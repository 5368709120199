import Categories from '../../components/Categories';

const Delivery = () => {
  return (
    <div>
      <Categories isDelivery={true} />
    </div>
  );
}

export default Delivery;